import React, { useCallback, useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import "./ChatSection.css";
import { IoMdSend } from "react-icons/io";
import moment from "moment";
import { useSelector } from "react-redux";
import { BsThreeDotsVertical } from "react-icons/bs";
import { CiMenuFries } from "react-icons/ci";
import ChatListing from "./ChatListing";

const SOCKET_URL = `https://igardu.com/iguardu-be/`;

const socket = io(SOCKET_URL, {
  withCredentials: true,
  transports: ["websocket"],
});

const ChatSection = ({
  selectedUser,
  setSelectedUser,
  isOpen,
  onClose,
  selectedJob,
  role,
  roomUrl,
  sendMessageUrl,
  getMessageUrl,
  userId,
}) => {
  const inputRef = useRef(null);
  const chatBodyRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [page, setPage] = useState(1);
  const [loadingOlderMessages, setLoadingOlderMessages] = useState(false);
  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  const { token, postLogin } = useSelector((state) => state.Authsection);
  const [loading, setLoading] = useState(false);
  const [isChatListingOpen, setIsChatListingOpen] = useState(false); // State to toggle chat listing

  const user_data = localStorage.getItem("user_data");
  const userData = JSON.parse(user_data);

  const toggleChatListing = () => {
    setIsChatListingOpen(!isChatListingOpen);
  };

  useEffect(() => {
    const handleNewMessage = (msg) => {
      if (msg.conversation_id !== selectedUser?.conversation_id) {
        return;
      }
      const message = {
        user_id: msg?.sender_id,
        name: msg?.name,
        role: msg?.role,
        text: msg?.text,
        created_on: msg?.meta?.created_on,
        id: msg?.meta?.message_id || msg?.meta?.created_on,
      };
      setMessages((prevMessage) => [...prevMessage, message]);
    };

    socket.on("new-message", handleNewMessage);

    return () => {
      socket.off("new-message", handleNewMessage);
    };
  }, [messages, userData.name, role]);

  const getMessages = useCallback(async () => {
    setLoading(true);
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/${role}/${getMessageUrl}/${selectedUser?.conversation_id}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = await response.json();
    if (data) {
      setMessages(data);
      setLoading(false);
    }
  }, [role, getMessageUrl, selectedUser?.conversation_id, token]);

  useEffect(() => {
    if (selectedUser?.conversation_id) {
      getMessages();
    }
  }, [selectedUser?.conversation_id]);

  // const createRoom = async () => {
  //   const payloadClient = {
  //     jobId: selectedJob?.job_id ? selectedJob?.job_id : selectedJob?.id,
  //     guardId: role === "guard" ? userId : selectedJob?.guard_id,
  //   };
  //   const payloadGuard = {
  //     jobId: selectedJob?.job_id ? selectedJob?.job_id : selectedJob?.id,
  //     clientId: role === "client" ? userId : selectedJob?.client_id,
  //   };

  //   if (role === "client") {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_BASE_URL}/${role}/${roomUrl}`,
  //       {
  //         method: "POST",
  //         headers: {
  //           "content-type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //         body: JSON.stringify(payloadClient),
  //       }
  //     );

  //     const data = await response.json();
  //     if (data && data?.success) {
  //       setConverstaionId(data?.data?.conversation_id);
  //     }
  //   } else if (role === "admin") {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_BASE_URL}/${role}/${roomUrl}`,
  //       {
  //         method: "POST",
  //         headers: {
  //           "content-type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //         body: JSON.stringify(payloadGuard),
  //       }
  //     );

  //     const data = await response.json();
  //     if (data && data?.success) {
  //       setConverstaionId(data?.data?.conversation_id);
  //     }
  //   } else {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_BASE_URL}/${role}/${roomUrl}`,
  //       {
  //         method: "POST",
  //         headers: {
  //           "content-type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //         body: JSON.stringify(payloadGuard),
  //       }
  //     );

  //     const data = await response.json();
  //     if (data && data?.success) {
  //       setConverstaionId(data?.data?.conversation_id);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   createRoom();
  // }, [roomUrl]);

  useEffect(() => {
    if (isOpen) {
      setPage(1);
      setMessages([]);
      setHasMoreMessages(true);
      inputRef.current?.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    const fetchMessages = () => {
      const payload = {
        job_id: selectedJob?.job_id ? selectedJob.job_id : selectedJob.id,
        user_type: role,
        page: page,
        limit: 10,
      };
      socket.emit("fetchMessages", payload);
    };

    if (isOpen) {
      fetchMessages();
    }
  }, [page, isOpen, selectedJob, role]);

  useEffect(() => {
    if (chatBodyRef.current && page === 1) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [messages]);

  // useEffect(() => {
  //   socket.on("new-message", (msg) => {
  //     console.log("Received new-message event with data:", msg);
  //     // setMessages((prevMessages) => [...prevMessages, msg]);
  //   });

  //   return () => {
  //     socket.off("new-message");
  //   };
  // }, [messages]);

  const handleSend = async () => {
    if (input.trim()) {
      const payload = {
        text: input,
        conversationId: selectedUser?.conversation_id,
      };

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/${role}/${sendMessageUrl}`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        }
      );

      const data = await response.json();
      if (data && data.success) {
        const message = {
          user_id: userId,
          name: userData.name,
          role: role,
          text: data?.data?.text,
          created_on: new Date(),
        };
        // setMessages((prevMessages) => [...prevMessages, message]);
      }

      // socket.emit("chatMessage", msg);
      // setMessages((prevMessages) => [...prevMessages, msg]);
      setInput("");
    }
  };

  const handleScroll = () => {
    if (
      chatBodyRef.current.scrollTop === 0 &&
      !loadingOlderMessages &&
      hasMoreMessages
    ) {
      setLoadingOlderMessages(true);
      setPage((prevPage) => prevPage + 1);
      setTimeout(() => {
        setLoadingOlderMessages(false);
      }, 500);
    }
  };

  return (
    <div className={`individual-chat-screen rounded-0 ${isOpen ? "open" : ""}`}>
      <div
        className={`fixed inset-0 z-50 md:hidden transform ${
          isChatListingOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out`}
      >
        <div
          className="absolute inset-0 bg-black opacity-50"
          onClick={toggleChatListing}
        ></div>
        <div className="relative bg-white w-4/5 h-full">
          <ChatListing setSelectedUser={setSelectedUser} />
        </div>
      </div>

      <div className="chat-header  flex items-center justify-between">
        <div className="flex items-center">
          <button className="md:hidden mr-2" onClick={toggleChatListing}>
            <CiMenuFries size={25} color="white" />
          </button>
          <span className="text-[17px] text-[white]">Admin</span>
        </div>
        <button onClick={onClose}>
          <BsThreeDotsVertical size={25} color="white" />
        </button>
      </div>
      <div className="chat-body" ref={chatBodyRef} onScroll={handleScroll}>
        {loading ? (
          <img src={"/loading.svg"} alt="loading" className="w-6 h-6" />
        ) : messages.length > 0 ? (
          <>
            {loadingOlderMessages && (
              <div className="flex justify-center items-center">
                <p>Loading older messages...</p>
              </div>
            )}
            {messages.map((msg, index) => (
              <div key={index} className="chat-item">
                <div
                  className="chat-message"
                  style={{
                    backgroundColor:
                      msg?.user_id === userData?.id ? "#007bff" : "#f1f1f1",
                    alignSelf:
                      msg?.user_id === userData?.id ? "flex-end" : "flex-start",
                    color: msg.user_id === userData?.id ? "white" : "black",
                  }}
                >
                  <p>{msg?.text}</p>
                </div>
                <span
                  className="timestamp"
                  style={{
                    fontSize: "10px",
                    alignSelf:
                      msg?.user_id === userData?.id ? "flex-end" : "flex-start",
                  }}
                >
                  {moment(msg?.created_on).format("MMM Do YYYY, h:mm:ss a")} •{" "}
                  {msg?.name} • {msg?.role}
                </span>
              </div>
            ))}
          </>
        ) : (
          <div className="flex justify-center items-center h-full">
            <p>Select a chat to continue conversation!</p>
          </div>
        )}
      </div>

      {selectedUser?.conversation_id && (
        <div className="chat-input">
          <input
            ref={inputRef}
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={(e) => e.key === "Enter" && handleSend()}
            placeholder="Type a message..."
          />
          <button onClick={handleSend} className="active-send">
            <IoMdSend size={25} color="white" />
          </button>
        </div>
      )}
    </div>
  );
};

export default ChatSection;
