import {
  GET_DASHBOARD_SUBADMIN,
  GET_DASHBOARD_ERROR_SUBADMIN,
  GET_DASHBOARD_SUCCESS_SUBADMIN,
  GET_DASHBOARD_CHARTS_SUBADMIN,
  GET_DASHBOARD_CHARTS_ERRORS_SUBADMIN,
  GET_DASHBOARD_CHARTS_SUCCESS_SUBADMIN,
  GET_TRADE_MEMBERS_ERROR_SUBADMIN,
  GET_TRADE_MEMBERS_SUCCESS_SUBADMIN,
  GET_USERS_SUBADMIN,
  GET_USERS_ERROR_SUBADMIN,
  GET_USERS_SUCCESS_SUBADMIN,
  GET_ADMIN_JOBS_ERROR_SUBADMIN,
  GET_ADMIN_JOBS_SUCCESS_SUBADMIN,
  GET_REVIEWS_SUBADMIN,
  GET_REVIEWS_ERROR_SUBADMIN,
  GET_REVIEWS_SUCCESS_SUBADMIN,
  PATCH_TRADE_MEMBERS_SUBADMIN,
  PATCH_TRADE_MEMBERS_ERROR_SUBADMIN,
  PATCH_TRADE_MEMBERS_SUCCESS_SUBADMIN,
  DELETE_USERS_SUBADMIN,
  DELETE_USERS_ERROR_SUBADMIN,
  DELETE_USERS_SUCCESS_SUBADMIN,
  PATCH_JOBS_SUBADMIN,
  PATCH_JOBS_ERROR_SUBADMIN,
  PATCH_JOBS_SUCCESS_SUBADMIN,
  PATCH_LICENSE_SUBADMIN,
  PATCH_LICENSE_ERROR_SUBADMIN,
  PATCH_LICENSE_SUCCESS_SUBADMIN,
  TRADER_UPDATED_SUBADMIN,
  PATCH_CLIENT_ERROR_SUBADMIN,
  PATCH_CLIENT_SUCCESS_SUBADMIN,
  JOB_UPDATED_SUBADMIN,
  PATCH_REVIEWS_SUBADMIN,
  PATCH_REVIEWS_ERROR_SUBADMIN,
  PATCH_REVIEWS_SUCCESS_SUBADMIN,
  GET_TRADE_MEMBERS_BETWEEN_DATES_SUBADMIN,
  GET_TRADE_MEMBERS_BETWEEN_DATES_ERROR_SUBADMIN,
  GET_USERS_BETWEEN_DATES_SUBADMIN,
  GET_USERS_BETWEEN_DATES_SUCCESS_SUBADMIN,
  GET_USERS_BETWEEN_DATES_ERROR_SUBADMIN,
  GET_JOBS_BETWEEN_DATES_SUBADMIN,
  GET_JOBS_BETWEEN_DATES_SUCCESS_SUBADMIN,
  GET_JOBS_BETWEEN_DATES_ERROR_SUBADMIN,
  GET_FILTERED_TRADE_MEMBERS_SUBADMIN,
  GET_FILTERED_TRADE_MEMBERS_SUCCESS_SUBADMIN,
  GET_FILTERED_TRADE_MEMBERS_ERROR_SUBADMIN,
  UPDATE_ADMIN_PASSWORD_SUBADMIN,
  UPDATE_ADMIN_PASSWORD_SUCCESS_SUBADMIN,
  UPDATE_ADMIN_PASSWORD_ERROR_SUBADMIN,
  UPDATE_PASSOWRD_SUBADMIN,
  UPDATE_PASSOWRD_SUCCESS_SUBADMIN,
  UPDATE_PASSOWRD_ERROR_SUBADMIN,
  LOGIN_DETAILS_DATA_SUBADMIN,
  LOGIN_DETAILS_DATA_SUCCESS_SUBADMIN,
  LOGIN_DETAILS_DATA_ERROR_SUBADMIN,
  UPLOAD_JOB_BANNER_SUBADMIN,
  UPLOAD_JOB_BANNER_SUCCESS_SUBADMIN,
  UPLOAD_JOB_BANNER_ERROR_SUBADMIN,
  GET_UPLOAD_JOB_BANNER_SUBADMIN,
  GET_UPLOAD_JOB_BANNER_SUCCESS_SUBADMIN,
  GET_UPLOAD_JOB_BANNER_ERROR_SUBADMIN,
  UPDATE_JOB_BANNER_SUBADMIN,
  UPDATE_JOB_BANNER_SUCCESS_SUBADMIN,
  UPDATE_JOB_BANNER_ERROR_SUBADMIN,
  ADD_SUB_ADMIN_SUBADMIN,
  ADD_SUB_ADMIN_SUCCESS_SUBADMIN,
  ADD_SUB_ADMIN_ERROR_SUBADMIN,
  UPDATE_SUB_ADMIN_SUBADMIN,
  UPDATE_SUB_ADMIN_SUCCESS_SUBADMIN,
  UPDATE_SUB_ADMIN_ERROR_SUBADMIN,
  GET_SUB_ADMIN_SUBADMIN,
  GET_SUB_ADMIN_SUCCESS_SUBADMIN,
  GET_SUB_ADMIN_ERROR_SUBADMIN,
  GET_TRADE_MEMBERS_SUBADMIN,
  GET_DASHBOARD_CHARTS_ERROR_SUBADMIN,
  GET_TRADE_MEMBERS_BETWEEN_DATES_SUCCESS_SUBADMIN,
  CLIENT_UPDATED_SUBADMIN,
  PATCH_CLIENT_SUBADMIN,
  GET_ADMIN_JOBS_SUBADMIN,
} from "../subadminactions";

const INIT_STATE = {
  getDashboard: {},
  getDashboardLoading: false,
  getDashboardError: "",
  getDashboardCharts: [],
  getDashboardChartsLoading: false,
  getDashboardChartsError: "",
  getTradeMembers: [],
  getTradeMembersLoading: false,
  getJTradeMembersError: "",
  getJobs: [],
  getJobsLoading: false,
  getJobsError: "",
  getUsers: [],
  getUsersLoading: false,
  getUsersError: "",
  getReviews: [],
  getReviewsLoading: false,
  getReviewsError: "",
  patchTradeMembers: [],
  patchTradeMembersLoading: false,
  patchTradeMembersError: "",
  isTraderUpdated: false,
  patchClient: {},
  patchClientLoading: false,
  isClientUpdated: false,
  deleteUsers: [],
  deleteUsersLoading: false,
  deleteUsersError: "",
  patchJobStatus: [],
  patchJobStatusLoading: false,
  patchJobStatusError: "",
  isJobUpdated: false,

  patchReview: {},
  patchReviewError: "",
  patchReviewLoading: false,

  patchLicenseStatus: {},
  patchLicenseStatusLoading: false,
  patchLicenseStatusError: "",

  getTradeMembersBetweenDates: null,
  getTradeMembersBetweenDatesLoading: false,
  getTradeMembersBetweenDatesError: "",

  getUsersBetweenDates: null,
  getUsersBetweenDatesLoading: false,
  getUsersBetweenDatesError: "",

  getJobsBetweenDates: null,
  getJobsBetweenDatesLoading: false,
  getJobsBetweenDatesError: "",

  getFilteredTradeMembers: null,
  getFilteredTradeMembersLoading: false,
  getFilteredTradeMembersError: "",

  updateAdminPassword: null,
  updateAdminPasswordLoading: false,
  updateAdminPasswordError: "",

  updatePassword: null,
  updatePasswordLoading: false,
  updatePasswordError: "",

  loginDetails: null,
  loginDetailsLoading: false,
  loginDetailsError: "",

  uploadJobBanner: null,
  uploadJobBannerLoading: false,
  uploadJobBannerError: "",

  getUploadedJobBanner: null,
  getUploadedJobBannerLoading: false,
  getUploadedJobBannerError: "",

  updateJobBanner: null,
  updateJobBannerLoading: false,
  updateJobBannerError: "",

  addSubAdmin: null,
  addSubAdminLoading: false,
  addSubAdminError: "",

  updateSubAdmin: null,
  updateSubAdminLoading: false,
  updateSubAdminError: "",

  getSubAdmins: null,
  getSubAdminsLoading: false,
  getSubAdminsError: "",
};

const SubadminSection = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DASHBOARD_SUBADMIN:
      return {
        ...state,
        getDashboard: {},
        getDashboardLoading: true,
      };
    case TRADER_UPDATED_SUBADMIN:
      return {
        ...state,
        isTraderUpdated: true,
      };
    case JOB_UPDATED_SUBADMIN:
      return {
        ...state,
        isJobUpdated: true,
      };
    case CLIENT_UPDATED_SUBADMIN:
      return {
        ...state,
        isClientUpdated: true,
      };

    case PATCH_REVIEWS_SUBADMIN:
      return {
        ...state,
        patchReviewLoading: true,
        patchReview: {},
      };
    case PATCH_REVIEWS_SUCCESS_SUBADMIN:
      return {
        ...state,
        patchReviewLoading: false,
        patchReview: action.payload,
      };
    case PATCH_REVIEWS_ERROR_SUBADMIN:
      return {
        ...state,
        patchReviewLoading: false,
      };
    case PATCH_CLIENT_SUBADMIN:
      return {
        ...state,
        patchClientLoading: true,
        patchClient: {},
      };
    case PATCH_CLIENT_SUCCESS_SUBADMIN:
      return {
        ...state,
        patchClientLoading: false,
        patchClient: action.payload,
      };
    case PATCH_CLIENT_ERROR_SUBADMIN:
      return {
        ...state,
        patchClientLoading: false,
      };
    case GET_DASHBOARD_SUCCESS_SUBADMIN:
      return {
        ...state,
        getDashboard: action.payload,
        getDashboardLoading: false,
        getDashboardError: "",
      };

    case GET_DASHBOARD_ERROR_SUBADMIN:
      return {
        ...state,
        getDashboardLoading: false,
        getDashboardError: action.payload?.message,
      };
    case GET_DASHBOARD_CHARTS_SUBADMIN:
      return {
        ...state,
        getDashboardCharts: [],
        getDashboardChartsLoading: true,
      };
    case GET_DASHBOARD_CHARTS_SUCCESS_SUBADMIN:
      return {
        ...state,
        getDashboardCharts: action.payload,
        getDashboardChartsLoading: false,
        getDashboardChartsError: "",
      };

    case GET_DASHBOARD_CHARTS_ERROR_SUBADMIN:
      return {
        ...state,
        getDashboardChartsLoading: false,
        getDashboardChartsError: action.payload?.message,
      };
    case GET_TRADE_MEMBERS_SUBADMIN:
      return {
        ...state,
        getTradeMembers: [],
        getTradeMembersLoading: true,
      };
    case GET_TRADE_MEMBERS_SUCCESS_SUBADMIN:
      return {
        ...state,
        getTradeMembers: action.payload,
        getTradeMembersLoading: false,
        getTradeMembersError: "",
      };

    case GET_TRADE_MEMBERS_ERROR_SUBADMIN:
      return {
        ...state,
        getTradeMembersLoading: false,
        getTradeMembersError: action.payload?.message,
      };
    case GET_USERS_SUBADMIN:
      return {
        ...state,
        getUsers: [],
        getUsersLoading: true,
      };
    case GET_USERS_SUCCESS_SUBADMIN:
      return {
        ...state,
        getUsers: action.payload,
        getUsersLoading: false,
        getUsersError: "",
      };

    case GET_USERS_ERROR_SUBADMIN:
      return {
        ...state,
        getUsersLoading: false,
        getUsersError: action.payload?.message,
      };
    case GET_ADMIN_JOBS_SUBADMIN:
      return {
        ...state,
        getJobs: [],
        getJobsLoading: true,
      };
    case GET_ADMIN_JOBS_SUCCESS_SUBADMIN:
      return {
        ...state,
        getJobs: action.payload,
        getJobsLoading: false,
        getJobsError: "",
      };

    case GET_ADMIN_JOBS_ERROR_SUBADMIN:
      return {
        ...state,
        getJobsLoading: false,
        getJobsError: action.payload?.message,
      };
    case GET_REVIEWS_SUBADMIN:
      return {
        ...state,
        getReviews: [],
        getReviewsLoading: true,
      };
    case GET_REVIEWS_SUCCESS_SUBADMIN:
      return {
        ...state,
        getReviews: action.payload,
        getReviewsLoading: false,
        getReviewsError: "",
      };

    case GET_REVIEWS_ERROR_SUBADMIN:
      return {
        ...state,
        getReviewsLoading: false,
        getReviewsError: action.payload?.message,
      };
    case PATCH_TRADE_MEMBERS_SUBADMIN:
      return {
        ...state,
        patchTradeMembers: [],
        patchTradeMembersLoading: true,
      };
    case PATCH_TRADE_MEMBERS_SUCCESS_SUBADMIN:
      return {
        ...state,
        patchTradeMembers: action.payload,
        patchTradeMembersLoading: false,
        patchTradeMembersError: "",
      };

    case PATCH_TRADE_MEMBERS_ERROR_SUBADMIN:
      return {
        ...state,
        patchTradeMembersLoading: false,
        patchTradeMembersError: action.payload.message,
      };
    case DELETE_USERS_SUBADMIN:
      return {
        ...state,
        deleteUsers: [],
        deleteUsersLoading: true,
      };
    case DELETE_USERS_SUCCESS_SUBADMIN:
      return {
        ...state,
        deleteUsers: action.payload,
        deleteUsersLoading: false,
        deleteUsersError: "",
      };

    case DELETE_USERS_ERROR_SUBADMIN:
      return {
        ...state,
        deleteUsersLoading: false,
        deleteUsersError: action.payload.message,
      };
    case PATCH_JOBS_SUBADMIN:
      return {
        ...state,
        patchJobStatus: [],
        patchJobStatusLoading: true,
      };
    case PATCH_JOBS_SUCCESS_SUBADMIN:
      return {
        ...state,
        patchJobStatus: action.payload,
        patchJobStatusLoading: false,
        patchJobStatusError: "",
      };

    case PATCH_JOBS_ERROR_SUBADMIN:
      return {
        ...state,
        patchJobStatusLoading: false,
        patchJobStatusError: action.payload.message,
      };

    case PATCH_LICENSE_SUBADMIN:
      return {
        ...state,
        patchLicenseStatus: [],
        patchLicenseStatusLoading: true,
      };
    case PATCH_LICENSE_SUCCESS_SUBADMIN:
      return {
        ...state,
        patchLicenseStatus: action.payload,
        patchLicenseStatusLoading: false,
        patchLicenseStatusError: "",
      };

    case PATCH_LICENSE_ERROR_SUBADMIN:
      return {
        ...state,
        patchLicenseStatusLoading: false,
        patchLicenseStatusError: action.payload.message,
      };

    case GET_TRADE_MEMBERS_BETWEEN_DATES_SUBADMIN:
      return {
        ...state,
        getTradeMembersBetweenDates: null,
        getTradeMembersBetweenDatesLoading: true,
      };
    case GET_TRADE_MEMBERS_BETWEEN_DATES_SUCCESS_SUBADMIN:
      return {
        ...state,
        getTradeMembersBetweenDates: action.payload,
        getTradeMembersBetweenDatesLoading: false,
        getTradeMembersBetweenDatesError: "",
      };

    case GET_TRADE_MEMBERS_BETWEEN_DATES_ERROR_SUBADMIN:
      return {
        ...state,
        getTradeMembersBetweenDatesLoading: false,
        getTradeMembersBetweenDatesError: action.payload?.message,
      };

    case GET_USERS_BETWEEN_DATES_SUBADMIN:
      return {
        ...state,
        getUsersBetweenDates: null,
        getUsersBetweenDatesLoading: true,
      };
    case GET_USERS_BETWEEN_DATES_SUCCESS_SUBADMIN:
      return {
        ...state,
        getUsersBetweenDates: action.payload,
        getUsersBetweenDatesLoading: false,
        getUsersBetweenDatesError: "",
      };

    case GET_USERS_BETWEEN_DATES_ERROR_SUBADMIN:
      return {
        ...state,
        getUsersBetweenDatesLoading: false,
        getUsersBetweenDatesError: action.payload?.message,
      };

    case GET_JOBS_BETWEEN_DATES_SUBADMIN:
      return {
        ...state,
        getJobsBetweenDates: null,
        getJobsBetweenDatesLoading: true,
      };
    case GET_JOBS_BETWEEN_DATES_SUCCESS_SUBADMIN:
      return {
        ...state,
        getJobsBetweenDates: action.payload,
        getJobsBetweenDatesLoading: false,
        getJobsBetweenDatesError: "",
      };

    case GET_JOBS_BETWEEN_DATES_ERROR_SUBADMIN:
      return {
        ...state,
        getJobsBetweenDatesLoading: false,
        getJobsBetweenDatesError: action.payload?.message,
      };

    case GET_FILTERED_TRADE_MEMBERS_SUBADMIN:
      return {
        ...state,
        getFilteredTradeMembers: null,
        getFilteredTradeMembersLoading: true,
      };
    case GET_FILTERED_TRADE_MEMBERS_SUCCESS_SUBADMIN:
      return {
        ...state,
        getFilteredTradeMembers: action.payload,
        getFilteredTradeMembersLoading: false,
        getFilteredTradeMembersError: "",
      };

    case GET_FILTERED_TRADE_MEMBERS_ERROR_SUBADMIN:
      return {
        ...state,
        getFilteredTradeMembersLoading: false,
        getFilteredTradeMembersError: action.payload?.message,
      };

    case UPDATE_ADMIN_PASSWORD_SUBADMIN:
      return {
        ...state,
        updateAdminPasswordLoading: true,
      };
    case UPDATE_ADMIN_PASSWORD_SUCCESS_SUBADMIN:
      return {
        ...state,
        updateAdminPassword: action.payload,
        updateAdminPasswordLoading: false,
        updateAdminPasswordError: "",
      };

    case UPDATE_ADMIN_PASSWORD_ERROR_SUBADMIN:
      return {
        ...state,
        updateAdminPasswordLoading: false,
        updateAdminPasswordError: action.payload.message,
      };

    case UPDATE_PASSOWRD_SUBADMIN:
      return {
        ...state,
        updatePasswordLoading: true,
      };
    case UPDATE_PASSOWRD_SUCCESS_SUBADMIN:
      return {
        ...state,
        updatePassword: action.payload,
        updatePasswordLoading: false,
        updatePasswordError: "",
      };

    case UPDATE_PASSOWRD_ERROR_SUBADMIN:
      return {
        ...state,
        updatePasswordLoading: false,
        updatePasswordError: action.payload.message,
      };

    case LOGIN_DETAILS_DATA_SUBADMIN:
      return {
        ...state,
        loginDetailsLoading: true,
      };
    case LOGIN_DETAILS_DATA_SUCCESS_SUBADMIN:
      return {
        ...state,
        loginDetails: action.payload,
        loginDetailsLoading: false,
        loginDetailsError: "",
      };

    case LOGIN_DETAILS_DATA_ERROR_SUBADMIN:
      return {
        ...state,
        loginDetailsLoading: false,
        loginDetailsError: action.payload.message,
      };

    case UPLOAD_JOB_BANNER_SUBADMIN:
      return {
        ...state,
        uploadJobBannerLoading: true,
      };
    case UPLOAD_JOB_BANNER_SUCCESS_SUBADMIN:
      return {
        ...state,
        uploadJobBanner: action.payload,
        uploadJobBannerLoading: false,
        uploadJobBannerError: "",
      };

    case UPDATE_ADMIN_PASSWORD_ERROR_SUBADMIN:
      return {
        ...state,
        uploadJobBannerLoading: false,
        uploadJobBannerError: action.payload.message,
      };

    case GET_UPLOAD_JOB_BANNER_SUBADMIN:
      return {
        ...state,
        getUploadedJobBannerLoading: true,
      };
    case GET_UPLOAD_JOB_BANNER_SUCCESS_SUBADMIN:
      return {
        ...state,
        getUploadedJobBanner: action.payload,
        getUploadedJobBannerLoading: false,
        getUploadedJobBannerError: "",
      };

    case GET_UPLOAD_JOB_BANNER_ERROR_SUBADMIN:
      return {
        ...state,
        getUploadedJobBannerLoading: false,
        getUploadedJobBannererror: action.payload.message,
      };

    case UPDATE_JOB_BANNER_SUBADMIN:
      return {
        ...state,
        updateJobBannerLoading: true,
      };
    case UPDATE_JOB_BANNER_SUCCESS_SUBADMIN:
      return {
        ...state,
        updateJobBanner: action.payload,
        updateJobBannerLoading: false,
        updateJobBannerError: "",
      };

    case UPDATE_JOB_BANNER_ERROR_SUBADMIN:
      return {
        ...state,
        updateJobBannerLoading: false,
        updateJobBannerError: action.payload.message,
      };

    case ADD_SUB_ADMIN_SUBADMIN:
      return {
        ...state,
        addSubAdminLoading: true,
      };
    case ADD_SUB_ADMIN_SUCCESS_SUBADMIN:
      return {
        ...state,
        addSubAdmin: action.payload,
        addSubAdminLoading: false,
        addSubAdminError: "",
      };

    case ADD_SUB_ADMIN_ERROR_SUBADMIN:
      return {
        ...state,
        addSubAdminLoading: false,
        addSubAdminError: action.payload.message,
      };

    case UPDATE_SUB_ADMIN_SUBADMIN:
      return {
        ...state,
        updateSubAdminLoading: true,
      };
    case UPDATE_SUB_ADMIN_SUCCESS_SUBADMIN:
      return {
        ...state,
        updateSubAdmin: action.payload,
        updateSubAdminLoading: false,
        updateSubAdminError: "",
      };

    case UPDATE_SUB_ADMIN_ERROR_SUBADMIN:
      return {
        ...state,
        updateSubAdminLoading: false,
        updateSubAdminError: action.payload.message,
      };

    case GET_SUB_ADMIN_SUBADMIN:
      return {
        ...state,
        getSubAdminsLoading: true,
      };
    case GET_SUB_ADMIN_SUCCESS_SUBADMIN:
      return {
        ...state,
        getSubAdmins: action.payload,
        getSubAdminsLoading: false,
        getSubAdminsError: "",
      };

    case GET_SUB_ADMIN_ERROR_SUBADMIN:
      return {
        ...state,
        getSubAdminsLoading: false,
        getSubAdminsError: action.payload.message,
      };

    default:
      return { ...state };
  }
};
export default SubadminSection;
