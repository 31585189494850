import React, { useEffect, useState } from "react";
import about_banner from "../../assets/images/about-us/about_banner.webp";
import about_banner_2 from "../../assets/images/about-us/about_banner_2.webp";
import arrow_right from "../../assets/images/about-us/arrow_right.svg";
import man_1 from "../../assets/images/about-us/man_1.webp";
import about_become_part from "../../assets/images/about-us/about_become_part.webp";
import about_become_part_3 from "../../assets/images/about-us/about_become_part_3.webp";

import Navbar from "../../pages/main/homepage/components/navbar";
import Footer from "../../pages/main/homepage/components/footer";

import FocusingOn from "./focusing-on";
import BecomeAPart from "./become-a-part.jsx";
import { Link } from "react-router-dom";

import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { getRole, getToken } from "../../redux/auth/action.js";

const _inital = {
  y: "20%",
  opacity: 0,
  scale: 0.8,
};

const _whileInView = {
  y: 1,
  opacity: 1,
  scale: 1,
  transition: { duration: 0.8 },
};

const _initialLeft = { x: "-40%", opacity: 0 };
const _whileInViewLeft = {
  x: 0,
  opacity: 1,
  transition: {
    delay: 0.2,
    duration: 0.8,
  },
};

const _initialRight = { x: "40%", opacity: 0 };
const _whileInViewRight = {
  x: 0,
  opacity: 1,
  transition: {
    delay: 0.2,
    duration: 0.8,
  },
};

const _viewport = { once: true };

function AboutUs() {
  const [login, setLogin] = useState(false);
  const [loginRole, setLoginRole] = useState("");
  const { token } = useSelector((state) => state.Authsection);
  const { role } = useSelector((state) => state.Authsection);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getToken());
    dispatch(getRole());
  }, []);

  useEffect(() => {
    if (token !== null && role !== null) {
      setLogin(true);
      setLoginRole(role);
    } else {
      setLogin(false);
      setLoginRole("");
    }
  }, [token, role]);
  return (
    <div className="overflow-x-hidden">
      <Navbar />
      <div
        className={`w-full  px-[20px] pt-[20px] mt-[100px] mb-[50px] lg:mb-[100px]`}
      >
        <div className="max-w-[1340px] mx-auto flex gap-[50px] lg:gap-0 flex-col lg:flex-row  items-center justify-center ">
          <div className="flex-1 ">
            <motion.h1
              initial={_initialLeft}
              whileInView={_whileInViewLeft}
              viewport={_viewport}
              className="text-[#1A202C] text-center lg:text-left font-[600] max-w-[517px] leading-[clamp(52px,calc(76/1440*100vw),76px)] text-[clamp(48px,calc(66/1440*100vw),66px)] lg:pr-[32px]"
            >
              Come <span className="text-[#145DEB]">change</span> the way the
              <span className="text-[#145DEB]"> security</span> world works
            </motion.h1>
            <motion.p
              initial={_initialLeft}
              whileInView={{
                ..._whileInViewLeft,
                transition: {
                  duration: 0.8,
                  delay: 0.3,
                },
              }}
              viewport={_viewport}
              className="text-[#64748B] text-center lg:text-left font-[400] max-w-[517px] leading-[24px] text-[16px] mt-[24px]"
            >
              Be part of the team that’s bringing this success to life
            </motion.p>

            <div className="flex items-center justify-center lg:justify-start gap-[16px] mt-[32px]">
              <Link to="/trader-signup">
                <motion.button
                  initial={_initialLeft}
                  whileInView={{
                    ..._whileInViewLeft,
                    transition: {
                      duration: 0.8,
                      delay: 0.5,
                    },
                  }}
                  viewport={_viewport}
                  className=" transition-all duration-300 text-white  bg-[#145DEB]   py-[12px] px-[28px] border-[#4c3cff] border  rounded-[36px] text-[14px] md:text-[16px]"
                >
                  Get Started
                </motion.button>
              </Link>
              {login ? (
                <Link
                  to={
                    loginRole !== "admin"
                      ? `/${loginRole}/dashboard`
                      : "/dashboard"
                  }
                >
                  <motion.button
                    initial={_initialLeft}
                    whileInView={{
                      ..._whileInViewLeft,
                      transition: {
                        duration: 0.8,
                        delay: 0.4,
                      },
                    }}
                    viewport={_viewport}
                    className="flex items-center gap-[6px] transition-all duration-300   text-[#323539]  py-[12px] px-[28px] border-[#E5E5E7] border rounded-[36px] text-[14px] md:text-[16px]"
                  >
                    Dashboard <img src={arrow_right} alt="arrow_right" />
                  </motion.button>
                </Link>
              ) : (
                <Link to="/sign-in">
                  <motion.button
                    initial={_initialLeft}
                    whileInView={{
                      ..._whileInViewLeft,
                      transition: {
                        duration: 0.8,
                        delay: 0.4,
                      },
                    }}
                    viewport={_viewport}
                    className="flex items-center gap-[6px] transition-all duration-300   text-[#323539]  py-[12px] px-[28px] border-[#E5E5E7] border rounded-[36px] text-[14px] md:text-[16px]"
                  >
                    Login <img src={arrow_right} alt="arrow_right" />
                  </motion.button>
                </Link>
              )}
            </div>
          </div>

          <div className="flex-1 max-w-[700px]">
            <motion.img
              initial={_inital}
              whileInView={_whileInView}
              viewport={_viewport}
              src={about_banner}
              alt="about_banner"
              className="w-full object-cover  rounded-[16px]"
            />
          </div>
        </div>
      </div>

      <div className={`w-full  px-[20px] py-[50px]  my-[50px] lg:my-[100px] `}>
        <div className="max-w-[1340px] mx-auto flex gap-[32px] lg:gap-0 flex-col lg:flex-row items-center justify-between">
          <div className="flex-1">
            <motion.h1
              initial={_inital}
              whileInView={_whileInView}
              viewport={_viewport}
              src={about_banner}
              className=" text-center lg:text-left text-[#000000] font-[600] max-w-[700px] lg:max-w-[551px] leading-[41px] text-[34px] lg:pr-[32px]"
            >
              Our mission is to empower lives and safeguard communities with
              excellence.
            </motion.h1>
          </div>

          <motion.p
            initial={_inital}
            whileInView={_whileInView}
            viewport={_viewport}
            src={about_banner}
            className="flex-1 text-[#323539] text-center lg:text-left font-[400] max-w-[700px] leading-[24px] text-[18px] "
          >
            Our mission is clear: to make safety and security a seamless
            reality. At Igardu, we are dedicated to delivering unmatched
            service, building trust, and empowering people to thrive in a secure
            and protected world.
          </motion.p>
        </div>
        <div className="max-w-[1340px] mx-auto w-full mt-[50px]">
          <motion.img
            initial={_inital}
            whileInView={_whileInView}
            viewport={_viewport}
            src={about_banner_2}
            alt="about_banner_2"
            className="w-full object-cover rounded-[16px] h-[200px] sm:h-[300px] lg:h-[420px]"
          />

          <motion.p
            initial={_inital}
            whileInView={_whileInView}
            viewport={_viewport}
            src={about_banner}
            className="mt-[50px] lg:mt-[100px] pt-[50px] lg:pt-[100px]   mx-auto text-[#145DEB] text-center font-[600] max-w-[1025px] leading-[33px] text-[28px]"
          >
            Our Mission - to create economic opportunities so people have better
            lives - is a global one. It’s also deeply personal to me as someone
            who grew up
          </motion.p>
        </div>
      </div>

      <BecomeAPart image={about_become_part} />

      <FocusingOn />

      <div className={`w-full  px-[20px] my-[30px] lg:my-[100px] pb-[0px]`}>
        <div className=" max-w-[1340px] mx-auto w-full  ">
          <motion.h3
            initial={_initialLeft}
            whileInView={_whileInViewLeft}
            viewport={_viewport}
            className=" text-[#145DEB] font-[600] leading-[53px] text-[44px] "
          >
            Our Impact
          </motion.h3>

          <div className="bg-[#B9B9B9] w-full my-[40px] h-[1px]" />

          <div className="pt-[20px] flex justify-between items-center gap-[32px] flex-col">
            {/* <motion.img
              initial={_inital}
              whileInView={_whileInView}
              viewport={_viewport}
              src={man_1}
              alt="man_1"
              className="h-[350px] w-[300px] lg:h-[450px] lg:w-[403px] xl:h-[507px] rounded-[16px]  xl:w-[463px] object-cover"
            /> */}

            <motion.div
              initial={_initialRight}
              whileInView={_whileInViewRight}
              viewport={_viewport}
            >
              <motion.p className=" text-center md:text-left max-w-[100%] text-[#145DEB] font-[600] leading-[30px] lg:leading-[33px] text-[24px] lg:text-[28px]">
                “Our mission is to create economic opportunities so that people
                have better lives. It attracts people to employ those who want
                to accomplish extraordinary things. And it’s the “Why” that
                guides where and how we seek to have impact.”
              </motion.p>
              <div className="bg-[#B9B9B9] w-full my-[32px] h-[1px]" />
              <h4 className="text-center md:text-left text-[#000000] font-[600] leading-[30px] lg:leading-[33px] text-[24px] lg:text-[28px]">
                P Collins
              </h4>

              <p className="text-center md:text-left text-[#323539] font-[400] leading-[21px] lg:leading-[21px] text-[16px] lg:text-[18px] mt-[4px]">
                President & CEO, IGardu
              </p>
            </motion.div>
          </div>
        </div>
      </div>

      <BecomeAPart image={about_become_part_3} />

      <Footer />
    </div>
  );
}

export default AboutUs;
